import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import PortableText from '@sanity/block-content-to-react'
import getYouTubeId from 'get-youtube-id'
import urlBuilder from '@sanity/image-url'

import YouTube from 'react-youtube'

import { Layout } from 'components/global'
import { media } from 'utils/media'
import GetImage from 'components/functional/getImage'

const StyledSpacer = styled.section`
  width: 100%;
  height: 100px;
  content: '';
  background: var(--white);
`

const Blog = ({ data }) => {
  const { description, slug, title, _rawBody, mainImage } = data.Page
  const pageMeta = {
    title: `${title} | Arnold Clark Cup`,
    description,
  }
  return (
    <Layout title={title} description={description} background>
      {mainImage ? <BlogHeader coverImage={mainImage} /> : <StyledSpacer />}
      <main>
        <BlogBody title={title} _rawBody={_rawBody} />
      </main>
    </Layout>
  )
}

export default Blog

const urlFor = source =>
  urlBuilder({
    projectId: 'm3n3vmpd',
    dataset: 'production',
  }).image(source)

const BlogHeaderStyles = styled.header`
  width: 100%;
  background-color: #fff;
  padding-top: 95px !important;
  .bheader {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    .gatsby-image-wrapper {
    }
  }
`
const BlogHeader = ({ coverImage }) => (
  <BlogHeaderStyles>
    <div className="bheader">
      <GetImage data={coverImage.asset} />
    </div>
  </BlogHeaderStyles>
)

const BlogBodyStyles = styled.article`
  width: 100%;
  background-color: #fff;
  .bb {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
    padding-bottom: 4rem;
    h1 {
      color: var(--darkGrey);
      @media ${media.xl} {
        font-size: 3.5rem;
      }
    }
    &__left {
      padding-top: 1.5rem;
      &--title {
        h5 {
          font-weight: 700;
          color: var(--darkGrey);
        }
        h1 {
          margin: 0.75rem 0;
          color: var(--darkGrey);
          @media ${media.xl} {
            font-size: 3rem;
          }
        }
        span {
          color: var(--darkGrey);
          font-weight: 700;
          display: inline-block;
        }
        p {
          margin-right: 5px;
          display: inline-block;
        }
      }
      &--body {
        margin-top: 1.5rem;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 1rem 0;
          color: var(--darkGrey);
          strong {
            color: var(--darkGrey);
          }
        }

        strong {
          color: #000;
        }
        p {
          line-height: 1.45;
          font-size: var(--text);
          margin: 1.2rem 0;
          color: var(--darkGrey);
        }
        ul {
          list-style: inside;
        }
        ol {
          list-style-position: inside;
        }
        li {
          margin: 0.5rem 0;
          line-height: 1.45;
          color: #000;
        }
        a {
          color: var(--teal);
          font-weight: 800;
          &:hover,
          &:active {
            color: var(--pink);
            text-decoration: underline;
          }
        }
        .blog-image {
          width: 100%;
          height: auto;
          img {
            width: 100%;
            height: auto;
          }
        }
        .image {
          img {
            width: 100%;
            height: auto;
          }
        }
        iframe {
          width: 100%;
        }
      }
    }

    @media only screen and (min-width: 750px) {
      display: grid;
      grid-template-columns: 85% 1fr;
      grid-gap: 1.5rem;
      position: relative;
      .sticky {
        position: sticky;
        top: 8rem;
      }
      &__right {
        position: static;
        top: 1rem;
        height: 100%;
        &--author {
          bottom: 5rem;
          display: block;
          section {
            width: 80px;
            margin: 0 auto 0.5rem auto;
            .gatsby-image-wrapper {
              width: 100%;
              height: auto;
            }
          }
          div {
            text-align: center;
          }
        }
        &--share {
        }
      }
    }
    @media only screen and (min-width: 1280px) {
      width: 70%;
      padding-top: 2rem;
    }
  }
`
const BlogBody = ({ _rawBody, title }) => {
  const serializers = {
    types: {
      image: props => (
        <div className="blog-image">
          <img
            src={`${urlFor(props.node.asset)
              .width(1388)
              .height(926)
              .fit('crop')
              .auto('format')}`}
            alt={title}
          />
        </div>
      ),
      videoEmbed: ({ node }) => {
        const { url } = node
        const id = getYouTubeId(url)
        return <YouTube videoId={id} className="video" />
      },
    },
  }
  return (
    <BlogBodyStyles>
      <div className="bb">
        <div className="bb__left">
          <div className="bb__left--body">
            <h1>{title}</h1>
            <PortableText blocks={_rawBody} serializers={serializers} />
          </div>
        </div>
      </div>
    </BlogBodyStyles>
  )
}

export const query = graphql`
  query SinglePageQuery($slug: String!) {
    Page: sanityPages(slug: { current: { eq: $slug } }) {
      title
      description
      slug {
        current
      }
      _rawBody
      mainImage {
        asset {
          gatsbyImageData(
            width: 900
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
          url
        }
      }
    }
  }
`
